/** **************************************
* Fonts: app
*************************************** */
@import '@fontsource-variable/public-sans';

@import '@fontsource/barlow/400.css';
@import '@fontsource/barlow/500.css';
@import '@fontsource/barlow/600.css';
@import '@fontsource/barlow/700.css';
@import '@fontsource/barlow/800.css';

/** **************************************
* Fonts: options
*************************************** */
@import '@fontsource-variable/dm-sans';
@import '@fontsource-variable/inter';
@import '@fontsource-variable/nunito-sans';

/** **************************************
* Plugins
*************************************** */
/* scrollbar */
@import './components/scrollbar/styles.css';

/* image */
@import './components/image/styles.css';

/* map */
@import './components/map/styles.css';

/* lightbox */
@import './components/lightbox/styles.css';

/* chart */
@import './components/chart/styles.css';

/** **************************************
* Baseline
*************************************** */
html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body,
#root,
#root__layout {
  display: flex;
  flex: 1 1 auto;
  min-height: 100%;
  flex-direction: column;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.recording-animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: var(--pulse-color);
  }
  50% {
    transform: scale(1.1);
    color: var(--pulse-dark-color);
  }
  100% {
    transform: scale(1);
    color: var(--pulse-color);
  }
}
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  border-radius: 2;
}
