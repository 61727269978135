@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';
@import 'yet-another-react-lightbox/plugins/thumbnails.css';

.yarl__root {
  --yarl__thumbnails_thumbnail_padding: 0;
  --yarl__thumbnails_thumbnail_border: transparent;
  --yarl__color_backdrop: rgba(var(--palette-grey-900Channel) / 0.5);
  --yarl__slide_captions_container_background: rgba(var(--palette-common-blackChannel) / 0.5);
  --yarl__carousel_padding_px: 0 !important;
}
.yarl__slide {
  background-color: black;
}
.yarl__slide_title {
  font-size: 20px;
  font-weight: 600;
}
.yarl__slide_description {
  font-size: 14px;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  display: none;
}

.yarl__slide_description::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
.yarl__button {
  filter: unset;
}
.yarl__thumbnails_thumbnail {
  opacity: 0.48;
  border-radius: 10px;
  border-color: transparent;
}
.yarl__thumbnails_thumbnail_active {
  opacity: 1;
  border-color: var(--palette-primary-main);
}
.yarl__thumbnails_vignette {
  --yarl__thumbnails_vignette_size: 0;
}
.yarl__video_container {
  background: var(--palette-common-black);
  /* min-width: 100vh !important; */
  max-width: none !important; /* Remove the max-width limitation */
  margin-bottom: 20px;
}
/* width: 100vw !important;  */
